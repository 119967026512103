//
//	Main javascript file
//	common.js
//	Created on 9.9.2014.
//
//	@author Magnus Hauge Bakke <magnus@idrift.no>
//	@copyright 2014 - 2014 iDrift Web AS
//	@version 1.0.0

$(function() {

	var $menu = $("#nav-main").clone();
	$menu.attr( "id", "mm-nav-main" );
	$menu.mmenu({
		dragOpen: true,
		searchfield: false,
		slidingSubmenus: true,
		offCanvas: {
			position  : "right"
		}
	});

	$('select').selectpicker();
	$('.timepicker-input').timepicker({
		show2400: true,
		step: 15,
		timeFormat: 'H:i'
	});

	$('#fz-button-plus').on('click', function(e){

		e.preventDefault();

		curSize= parseInt($('.article-content').css('font-size')) + 2;

		if( curSize <= 48 ) {
			$('.article-content').css('font-size', curSize);
		}

	});

	$('#fz-button-minus').on('click', function(e){

		e.preventDefault();

		curSize= parseInt($('.article-content').css('font-size')) - 2;

		if( curSize >= 14 ) {
			$('.article-content').css('font-size', curSize);
		}

	});


	if( $('#plupload').length ) {

		var options = {
			max_file_size: '5mb'
		};

		if ( typeof(plupload_options) != "undefined" ) {
			options = plupload_options;
		}
		 
		$('#plupload').plupload({

			// General settings
			runtimes : 'html5,flash,silverlight,html4',
			url : "/admin/uploads?plupload=true",
			multipart_params : {
				"_token" : $('meta[name="csrf-token"]').attr('content')
			},

			// Maximum file size
			max_file_size : options.max_file_size,

			chunk_size: '2mb',

			// Specify what files to browse for
			// filters : [
			// 	{title : "Image files", extensions : "jpg,gif,png"},
			// 	{title : "Zip files", extensions : "zip,avi"}
			// ],

			// Enable ability to drag'n'drop files onto the widget (currently only HTML5 supports that)
			dragdrop: true,
			sortable: false,
			autostart: true,

			// Views to activate
			views: {
				list: true,
				thumbs: false, // Show thumbs
				active: 'list'
			},

			buttons: {
				browse: true,
				start: false,
				stop: false
			},

			// Flash settings
			flash_swf_url : '/plupload/Moxie.swf',

			// Silverlight settings
			silverlight_xap_url : '/plupload/Moxie.xap'

		});

	}


	if( $('#new-user').length ) {

		$('#new-user').on('change', function(e) {

			// Get new user id
			var $select = $(this),
				user_id = $select.val(),
				$table  = $('#table-users'),
				$tbody  = $table.find('tbody');

			// Check user id
			if( user_id ) {

				$.ajax({
					url: '/api/v1/gradeuser',
					type: 'POST',
					dataType: 'json',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
						'X-Auth-Token': ( API_Token ? API_Token : '' )
					},
					data: {
						grade_id: Grade_ID,
						user_id: user_id
					}
				}).done( function(response) {

					if( ! response.error ) {

						var user = response.data;

						// Setup new user row
						var newRow = '<tr>';
						newRow += '<td>' + user.firstname + ' ' + user.lastname + '</td>';
						newRow += '<td>' + user.roles + '</td>';
						newRow += '<td><a href="#" class="remove-user btn btn-danger btn-sm" data-user-id="' + user.id + '"><i class="fa fa-times"></i> Fjern bruker</a></td>';
						newRow += '</tr>';

						// Append user to tbody
						$tbody.append(newRow);

						// Reset select
						$select.selectpicker('val', '');

					}

				}).fail( function() {
					alert( 'Noe gikk galt!' );
				});

			}

		});

		$('#table-users').on('click', '.remove-user', function(e) {

			e.preventDefault();

			var $button   = $(this),
				$tableRow = $button.closest('tr'),
				user_id   = $(this).attr('data-user-id');

			// Check user id
			if( user_id ) {

				$.ajax({
					url: '/api/v1/gradeuser',
					type: 'POST',
					dataType: 'json',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
						'X-Auth-Token': ( API_Token ? API_Token : '' )
					},
					data: {
						grade_id: Grade_ID,
						user_id: user_id,
						_method : "delete"
					}
				}).done( function(response) {

					// Remove table row
					$tableRow.remove();

				}).fail( function() {
					alert( 'Noe gikk galt!' );
				});

			}

		});

	}

	function centerModal() {
		$(this).css('display', 'block');
		var $dialog = $(this).find(".modal-dialog");
		var offset = ($(window).height() - $dialog.height()) / 2;
		$dialog.css("margin-top", offset);
	}

	// $('.modal.modal-vertical-align').on('show.bs.modal', centerModal);
	// $(window).on("resize", function () {
	// 	$('.modal.modal-vertical-align:visible').each(centerModal);
	// });

});

// End of file common.js
// Location: /Users/Bakke/Development/vhost/tanntastisk.dev/app/assets/javascripts/common.js
