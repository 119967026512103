//
//	Javascript for lessons timetable
//	lessons.js
//	Created on 10.9.2014.
//
//	@author Magnus Hauge Bakke <magnus@idrift.no>
//	@copyright 2014 - 2014 iDrift Web AS
//	@version 1.0.0

$(window).load( function() {

	var Lessons = {

		form: null,
		$dialog: null,
		$form: null,
		$calendar: $('#calendar'),
		$calendarGrade: $('#calendar-grade'),
		$showCalendar: $('#show-calendar'),
		$title: $( "#title-input" ),
		$info: $( "#info-select" ),
		$lecture: $( "#lecture-select" ),
		//$activity: $( "#activity-select" ),
		$category: $( "#category-select" ),
		$btnSubmit: $('#submit-lesson'),
		$btnDelete: $('#delete-lesson'),
		allFields: $( [] ).add( self.$title ).add( self.$info ).add( self.$lecture ).add( self.$category ),
		fullCalendarSettings: {
			height: 'auto',
			defaultDate: '2014-01-01',
			defaultView: 'agendaWeek',
			weekends: false,
			allDaySlot: false,
			axisFormat: 'HH(:mm)',
			slotDuration: '00:15:00',
			snapDuration: '00:15:00',
			minTime: '06:00:00',
			maxTime: '17:00:00',
			header: {
				left:   '',
				center: '',
				right:  ''
			},
			columnFormat: {
				month: 'ddd',
				week: 'dddd',
				day: 'dddd'
			},
			timeFormat: 'H(:mm)',
			dayNames: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
			dayNamesShort: ['Søn.', 'Man.', 'Tir.', 'Ons.', 'Tor.', 'Fre.', 'Lør.'],
			monthNames: ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember']
		},


		/**
		 * Init class
		 * @return {void}
		 */
		init: function() {

			// Init dialog
			Lessons.initDialog();

			// Init FullCalendar
			Lessons.initFullCalendar();

		},


		/**
		 * Handle select events in fullcalendar
		 * @param  {Moment} start Start time
		 * @param  {Moment} stop  End time
		 * @param  {object} event JS event object
		 * @param  {object} view  Current fullcalendar view object
		 * @return {void}
		 */
		fullCalendarSelect: function(start, stop, event, view) {
			// Edit dialog buttons
			Lessons.$btnSubmit.val('Legg til time');
			Lessons.$btnDelete.hide();

			// Open dialog
			Lessons.$dialog.dialog( "open" );

			// On form submit
			Lessons.$form = Lessons.$dialog.find( "form" ).on( "submit", function(e) {

				// Prevent default behaviour
				e.preventDefault();

				// Get options
				var title    = ( Lessons.$title.val() ? Lessons.$title.val() : '' ),
					info     = ( Lessons.$info.find('option:selected').val() ? Lessons.$info.find('option:selected').val() : '' ),
					lecture  = ( Lessons.$lecture.find('option:selected').val() ? Lessons.$lecture.find('option:selected').val() : '' ),
					//activity = ( Lessons.$activity.find('option:selected').val() ? Lessons.$activity.find('option:selected').val() : '' ),
					category = ( Lessons.$category.find('option:selected').val() ? Lessons.$category.find('option:selected').val() : '' ),
					articles = [];

				// Create artices array
				if( info ) articles.push( info );
				if( lecture ) articles.push( lecture );
				//if( activity ) articles.push( activity );

				// Store new lesson to db
				$.ajax({
					url: '/api/v1/lessons',
					type: 'POST',
					dataType: 'json',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
						'X-Auth-Token': ( API_Token ? API_Token : '' )
					},
					data: {
						title: title,
						timestart: start.format('HH:mm:ss'),
						timestop: stop.format('HH:mm:ss'),
						day: start.format('d'),
						moment_start: start.format(),
						moment_end: stop.format(),
						articles: articles,
						category: category
					}
				}).done( function( response ) {

					// Render new lesson
					Lessons.$calendar.fullCalendar( 'renderEvent', {
						id: response.data.id,
						title: title,
						start: start,
						end: stop,
						article_ids: articles,
						articles_array: response.data.articles_array,
						category: category
					}, true );

				}).fail( function(response) {

					alert( 'Noe gikk galt!' );

				});

				// Close dialog
				Lessons.$dialog.dialog( "close" );

			});

		},


		/**
		 * Handle click on event in fullcalendar
		 * @param  {object} event Current event
		 * @param  {object} e     JS event object
		 * @param  {object} view  Current fullcalendar view object
		 * @return {void}
		 */
		fullCalendarEventClick: function( event, e, view ) {
			// Allow link clicks
			if( e.target.nodeName == 'A' )
				return;

			// Edit dialog buttons
			Lessons.$btnSubmit.val('Oppdater');
			Lessons.$btnDelete.show();

			// Open and populate dialog for updating the lesson
			Lessons.$title.val( event.title );
			Lessons.$dialog.find('option').filter( function() {
				return ( event.article_ids && event.article_ids.indexOf( $(this).val().toString() ) != -1 );
			}).attr('selected', true);

			Lessons.$category.val(event.category);

			// Open dialog
			Lessons.$dialog.dialog( "open" );

			// On form submit
			Lessons.$form = Lessons.$dialog.find( "form" ).on( "submit", function(e) {

				// Prevent default behaviour
				e.preventDefault();

				// Get options
				event.title       = ( Lessons.$title.val() ? Lessons.$title.val() : '' );
				event.article_ids = [];
				info              = ( Lessons.$info.find('option:selected').val() ? Lessons.$info.find('option:selected').val() : '' );
				lecture           = ( Lessons.$lecture.find('option:selected').val() ? Lessons.$lecture.find('option:selected').val() : '' );
				//activity          = ( Lessons.$activity.find('option:selected').val() ? Lessons.$activity.find('option:selected').val() : '' );
				event.category    = ( Lessons.$category.find('option:selected').val() ? Lessons.$category.find('option:selected').val() : '' );



				// Create artices array
				if( info ) event.article_ids.push( info );
				if( lecture ) event.article_ids.push( lecture );
				//if( activity ) event.article_ids.push( activity );

				// Store new lesson to db
				$.ajax({
					url: '/api/v1/lessons/' + event.id,
					type: 'PUT',
					dataType: 'json',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
						'X-Auth-Token': ( API_Token ? API_Token : '' )
					},
					data: {
						title: event.title,
						timestart: event.start.format('HH:mm:ss'),
						timestop: event.end.format('HH:mm:ss'),
						day: event.start.format('d'),
						moment_start: event.start.format(),
						moment_end: event.end.format(),
						articles: event.article_ids,
						category: event.category
					}
				}).done( function( response ) {
					event.articles_array = response.data.articles_array;

					// Update event in calendar
					Lessons.$calendar.fullCalendar( 'updateEvent', event, true );

				}).fail( function() {

					alert( 'Noe gikk galt!' );

				});

				// Close dialog
				Lessons.$dialog.dialog( "close" );

			});

			Lessons.$btnDelete.on('click', function(e) {

				// Prevent default behaviour
				e.preventDefault();

				var confirmation = confirm('Er du sikker på at du vil slette denne timen?');

				if( confirmation ) {

					// Remove lesson
					$.ajax({
						url: '/api/v1/lessons/' + event.id,
						type: 'DELETE',
						dataType: 'json',
						headers: {
							'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
							'X-Auth-Token': ( API_Token ? API_Token : '' )
						},
					}).done( function( response ) {
						// Update event in calendar
						Lessons.$calendar.fullCalendar( 'removeEvents', event.id );

					}).fail( function() {

						alert( 'Noe gikk galt!' );

					});

					// Close dialog
					Lessons.$dialog.dialog( "close" );

				}

			});

		},


		/**
		 * Handle event drag and resize events
		 * @param  {object} event        Current event
		 * @param  {object} d            Duration object
		 * @param  {function} revertFunc Revrt function
		 * @param  {object} e            JS event object
		 * @param  {object} ui           Jquery UI object
		 * @param  {object} view         Current fullcalendar view object
		 * @return {void}
		 */
		fullCalendarEventChange: function(event, d, revertFunc, e, ui, view) {

			// Store new lesson to db
			$.ajax({
				url: '/api/v1/lessons/' + event.id,
				type: 'PUT',
				dataType: 'json',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					'X-Auth-Token': ( API_Token ? API_Token : '' )
				},
				data: {
					title: event.title,
					timestart: event.start.format('HH:mm:ss'),
					timestop: event.end.format('HH:mm:ss'),
					day: event.start.format('d'),
					moment_start: event.start.format(),
					moment_end: event.end.format(),
					articles: event.article_ids
				}
			}).done( function( response ) {
				// Done callback

			}).fail( function() {

				alert( 'Noe gikk galt!' );

			});

		},


		/**
		 * Handle event drag and resize events for specific class
		 * @param  {object} event        Current event
		 * @param  {object} d            Duration object
		 * @param  {function} revertFunc Revrt function
		 * @param  {object} e            JS event object
		 * @param  {object} ui           Jquery UI object
		 * @param  {object} view         Current fullcalendar view object
		 * @return {void}
		 */
		fullCalendarEventChangeClass: function(event, d, revertFunc, e, ui, view) {
			// Check grade ID
			if( ! Grade_ID ) {
				alert('Kunne ikke finne klasse.');
				return false;
			}

			// Store new lesson to db
			$.ajax({
				url: '/api/v1/grade_lesson/' + Grade_ID + '/' + event.id,
				type: 'PUT',
				dataType: 'json',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					'X-Auth-Token': ( API_Token ? API_Token : '' )
				},
				data: {
					timestart: event.start.format('HH:mm:ss'),
					timestop: event.end.format('HH:mm:ss'),
					day: event.start.format('d'),
					moment_start: event.start.format(),
					moment_end: event.end.format()
				}
			}).done( function( response ) {
				// Done callback

			}).fail( function(response, data) {

				alert( 'Noe gikk galt!' );

			});

		},


		/**
		 * Modify events on render
		 * @param  {object} event          Current event
		 * @param  {jQuery object} element Event jQuery object
		 * @param  {object} view           Current fullcalendar view object
		 * @return {void}
		 */
		fullCalendarEventRender: function(event, element, view) {
			var key;

			for( key in event.articles_array ) {

				var slug;

				switch ( event.articles_array[key].type ) {
					case 'info':
						slug = 'info';
						break;
					case 'lecture':
						slug = 'lectures';
						break;
					case 'activity':
						slug = 'activities';
						break;
					default:
						slug = 'articles';
				}

				element.find('.fc-content').append( '<br/><a href="/' + slug + '/' + event.articles_array[key].id + '">' + event.articles_array[key].title + '</a>' );
			}

			if (event.category !== '')
				element.addClass(event.category);

		},


		/**
		 * Init dialog
		 * @return {void}
		 */
		initDialog: function() {
			if( $( "#lesson-form" ).length ) {

				Lessons.$dialog = $( "#lesson-form" ).dialog({
					autoOpen: false,
					// height: 300,
					width: 350,
					modal: true,
					buttons: {},
					close: function() {
						Lessons.$dialog.find('input[type="text"]').val('');
						Lessons.$dialog.find('option').attr('selected', false);
						Lessons.allFields.removeClass( "ui-state-error" );
						Lessons.$dialog.find( "form" ).off('submit');
						Lessons.$btnDelete.off('click');
					},
					open: function(event, ui) {
						// remove default close icon
						$('.ui-dialog-titlebar-close span').removeClass('ui-icon ui-icon-thickclose');

						// Yuck they have close text let's remove that
						$('.ui-dialog-titlebar-close span').text('');

						// Lets add font awesome close icon
						$('.ui-dialog-titlebar-close span').addClass('fa fa-times');

						// ok lets remove the default underline for a hyperlink
						$('.ui-dialog-titlebar-close').css('text-decoration','none');

						// I also like to load the screen here too
						$('#dialogScreen').load('pages/options.html',function(){
							$(this).trigger('create');
						});
					}
				});

			}

		},


		/**
		 * Init FullCalendar
		 * @return {void}
		 */
		initFullCalendar: function() {

			var settings;

			// Edit main calendar
			if( Lessons.$calendar.length ) {

				settings = $.extend( Lessons.fullCalendarSettings, {
					events: ( Timetable_Events ? Timetable_Events : [] ),
					editable: true,
					selectable: true,
					selectHelper: true,
					select: Lessons.fullCalendarSelect,
					eventClick: Lessons.fullCalendarEventClick,
					eventDrop: Lessons.fullCalendarEventChange,
					eventResize: Lessons.fullCalendarEventChange,
					eventRender: Lessons.fullCalendarEventRender
				});

				Lessons.$calendar.fullCalendar(settings);

			}

			// Edit grade claendar
			if( Lessons.$calendarGrade.length ) {

				settings = $.extend( Lessons.fullCalendarSettings, {
					events: ( Timetable_Events ? Timetable_Events : [] ),
					// editable: true,
					eventStartEditable: true,
					eventDurationEditable: false,
					selectable: false,
					selectHelper: false,
					// select: Lessons.fullCalendarSelect,
					// eventClick: Lessons.fullCalendarEventClick,
					eventDrop: Lessons.fullCalendarEventChangeClass,
					// eventResize: Lessons.fullCalendarEventChange,
					eventRender: Lessons.fullCalendarEventRender
				});

				Lessons.$calendarGrade.fullCalendar(settings);

			}

			// Show a calendar
			if( Lessons.$showCalendar.length ) {

				settings = $.extend( Lessons.fullCalendarSettings, {
					events: ( Timetable_Events ? Timetable_Events : [] ),
					editable: false,
					selectable: false,
					selectHelper: false,
					eventRender: Lessons.fullCalendarEventRender
				});

				Lessons.$showCalendar.fullCalendar(settings);

			}

		}

	};

	// Init class
	Lessons.init();

});

// End of file lessons.js
// Location: /Users/Bakke/Development/vhost/tanntastisk.dev/app/assets/javascripts/lessons.js
