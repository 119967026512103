//
//	Javascript for test questions
//	test.js
//	Created on 28.8.2014.
//
//	@author Magnus Hauge Bakke <magnus@idrift.no>
//	@copyright 2014 - 2014 iDrift Web AS
//	@version 1.0.0

$(window).load( function() {

	if( ! $('#questions').length )
		return;

	var Test = {
		Models: {},
		Collections: {},
		Views: {},
		Helpers: {}
	};


	/**
	 * Helper functions
	 */
	Test.Helpers.template = function(id) {
		return _.template( $('#' + id).html() );
	};


	/**
	 * Question model
	 */
	Test.Models.Question = Backbone.Model.extend({

		// Define defaults
		defaults: {
			question_id: false,
			index: 0,
			active: '',
			direction: 'Nytt spørsmål',
			answers: []
		}

	});


	/**
	 * Questions collection
	 */
	Test.Collections.Questions = Backbone.Collection.extend({

		// Set model type
		model: Test.Models.Question,


		/**
		 * Initialize collection
		 * @return {void}
		 */
		initialize: function() {

			// Set indexes after init
			_.defer( _.bind( this.updateIndexes, this ) );

			// Set index when item is added
			this.on('add', this.setIndex, this);

			// Update indexes when model is removed
			this.on('remove', this.updateIndexes, this);

		},


		/**
		 * Set index when item is added
		 * @return {void}
		 */
		setIndex: function(question) {

			// Set answer index
			question.set('index', this.length - 1);

		},


		/**
		 * Update index property on all models
		 * @return {void}
		 */
		updateIndexes: function() {

			// Loop through all models
			this.each( function(question, index) {

				// Set index for question
				question.set('index', index);

				// Loop through answers
				question.get('answers').collection.each( function(answer, i) {

					// Set question index for answer
					answer.set('questionIndex', index);

				});

			});

		}

	});


	/**
	 * Answer model
	 */
	Test.Models.Answer = Backbone.Model.extend({

		// Define defaults
		defaults: {
			index: 0,
			questionIndex: 0,
			correct: false,
			label: '',
			value: ''
		}

	});


	/**
	 * Answers collection
	 */
	Test.Collections.Answers = Backbone.Collection.extend({

		// Set model type
		model: Test.Models.Answer,


		/**
		 * Initialize collection
		 * @return {void}
		 */
		initialize: function() {

			// Set indexes after init
			_.defer( _.bind( this.updateIndexes, this ) );

			// Set index when item is added
			this.on('add', this.setIndex, this);

			// Update indexes when model is removed
			this.on('remove', this.updateIndexes, this);

		},


		/**
		 * Set index when item is added
		 * @return {void}
		 */
		setIndex: function(answer) {

			var index = this.length - 1;

			// Set answer index
			answer.set('index', index);

		},


		/**
		 * Update index property on all models
		 * @return {void}
		 */
		updateIndexes: function() {

			// Loop through all models
			this.each( function(answer, index) {

				// Set index for answer
				answer.set('index', index);

			});

		}

	});


	/**
	 * Single question view
	 */
	Test.Views.Question = Backbone.View.extend({

		// Set view options
		tagName: 'div',
		className: 'question',

		// Define template
		template: Test.Helpers.template( 'question-template' ),

		// Set events
		events: {
			'click .edit-question': 'toggleEdit',
			'click .remove-question': 'destroyQuestion',
			'click .add-answer': 'addAnswer',
			'change .question-form .question-direction': 'updateDirection',
		},


		/**
		 * Initialize view
		 * @return {void}
		 */
		initialize: function() {

			// On change re-render
			this.model.on('change', this.render, this);

			// Remove view when model is destroyed
			this.model.on('destroy', this.remove, this);

			// Create answers view on first render
			this.createAnswersView();

		},


		/**
		 * Render question view
		 * @return {object} View object
		 */
		render: function() {

			// Render HTML for jQuery element
			this.$el.html( this.template( this.model.toJSON() ) );

			// Append answers view
			this.$el.find('.question-answers').append( this.model.get('answers').render().el );

			// Return view object
			return this;

		},


		/**
		 * Toggle edit form for question
		 * @param  {object} e Event object
		 * @return {void}
		 */
		toggleEdit: function(e) {

			e.preventDefault();

			// Toggle is-active class on question
			this.$el.toggleClass('is-active');

		},


		/**
		 * Destroy model
		 * @return {void}
		 */
		destroyQuestion: function() {

			// Destroy model
			this.model.destroy();

		},


		/**
		 * Create view for ansers
		 * @return {void}
		 */
		createAnswersView: function() {

			// See if we have any answers
			var pre_answers = this.model.get('answers');
			var answers = ( pre_answers ? pre_answers : [] );

			// Create ansers collection
			var answersCollection = new Test.Collections.Answers(answers);

			// Create ansers collection view
			var answersView = new Test.Views.Answers({
				collection: answersCollection
			});

			// Add ansers view to model
			this.model.set( 'answers', answersView );

		},


		/**
		 * Update direction in header
		 * @param  {object} e Event object
		 * @return {void}
		 */
		updateDirection: function(e) {

			// Get current value
			var value = e.currentTarget.value;

			// Update model
			this.model.set('direction', value);

		},


		/**
		 * Add new answer to answers collection
		 * @param {object} e Event object
		 */
		addAnswer: function(e) {

			e.preventDefault();

			var questionIndex = this.model.collection.indexOf( this.model );

			// Add new model to answers collection
			this.model.get('answers').collection.add({
				questionIndex: questionIndex
			});

		}

	});


	/**
	 * Questions collection view
	 */
	Test.Views.Questions = Backbone.View.extend({

		// Set view options
		el: '#questions',


		/**
		 * Initialize view
		 * @return {void}
		 */
		initialize: function() {

			// Add all items already in collection
			this.collection.each( this.addItem, this);

			// Listen for add event on collection
			this.collection.on('add', this.addItem, this);

		},


		/**
		 * Render collection view
		 * @return {object} View object
		 */
		render: function() {

			// Add all items in collection to view
			this.collection.each( this.addItem, this);

			// Return view object
			return this;

		},


		/**
		 * Add new question view from model
		 * @param {object} question Question model
		 */
		addItem: function(question) {

			// Create new question view from model
			var questionView = new Test.Views.Question({ model: question });

			// Render and append new question view
			this.$el.append( questionView.render().el );

		}

	});


	/**
	 * Single answer view
	 */
	Test.Views.Answer = Backbone.View.extend({

		// Set view options
		tagName: 'tr',

		// Define template
		template: Test.Helpers.template( 'answer-template' ),

		// Events
		events: {
			'change input[type="text"]': 'updateLabel',
			'change select': 'updateValue',
			'click .remove-answer': 'destroy'
		},


		/**
		 * Initialize view
		 * @return {void}
		 */
		initialize: function() {

			// Remove view when model is destroyed
			this.model.on('destroy', this.remove, this);

			// Re-render on changes in model
			this.model.on('change:values', this.render, this);
			this.model.on('change:index', this.render, this);
			this.model.on('change:questionIndex', this.render, this);

		},


		/**
		 * Render answer view
		 * @return {object} View object
		 */
		render: function() {

			// Render HTML for jQuery element
			this.$el.html( this.template( this.model.toJSON() ) );

			// Return this view
			return this;

		},


		/**
		 * Update label on model
		 * @param  {object} e Event object
		 * @return {void}
		 */
		updateLabel: function(e) {

			// Get current value
			var value = e.currentTarget.value;

			// Update model
			this.model.set('label', value);

		},


		/**
		 * Update value on model
		 * @param  {object} e Event object
		 * @return {void}
		 */
		updateValue: function(e) {

			// Get current value
			var value = e.currentTarget.value;

			// Update model
			this.model.set('value', value);

		},


		/**
		 * Destroy model
		 * @return {void}
		 */
		destroy: function() {

			// Destroy model
			this.model.destroy();

		}

	});


	/**
	 * Answers collection view
	 */
	Test.Views.Answers = Backbone.View.extend({

		// Set view options
		tagName: 'tbody',


		/**
		 * Initialize view
		 * @return {void}
		 */
		initialize: function() {

			// Add already existing answers
			this.collection.each( this.addItem, this);

			// Listen for add event on collection
			this.collection.on('add', this.addItem, this);

		},


		/**
		 * Render collection view
		 * @return {object} View object
		 */
		render: function() {

			// Keep elements events
			this.$el.empty();
			this.delegateEvents();

			// Add all items in collection to view
			this.collection.each( this.addItem, this);

			// Return view object
			return this;

		},


		/**
		 * Add new answer view from model
		 * @param {object} answer Answer model
		 */
		addItem: function(answer) {

			// Create new answer view from model
			var answerView = new Test.Views.Answer({ model: answer });

			// Render and append new answer view
			this.$el.append( answerView.render().el );

		}

	});

	// Create new collection for questions
	var questionsCollection = new Test.Collections.Questions( Test_Questions );

	// Create view for questions collection
	var questionsView = new Test.Views.Questions({ collection: questionsCollection });

	// Add new test question
	$('#add-question').on('click', function(e) {

		e.preventDefault();

		// Add question to collection
		questionsCollection.add({});

	} );

});

// End of file test.js
// Location: /Users/Bakke/Development/vhost/tanntastisk.dev/app/assets/stylesheets/test.js
